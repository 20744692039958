import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Nav, Collapse } from 'react-bootstrap';
import NavbarVerticalMenuItem from './NavbarVerticalMenuItem';
import classNames from 'classnames';
import AppContext from 'context/Context';
import {
  firstProTier,
  canShowRoute,
  isTraderCompleted
} from 'staticData/common';
import { isInTestAB, portfolioTest } from 'services/testAB/functions';

const CollapseItems = ({ route }) => {
  const { pathname } = useLocation();

  const openCollapse = childrens => {
    const checkLink = children => {
      if (children.to === pathname) {
        return true;
      }
      return (
        Object.prototype.hasOwnProperty.call(children, 'children') &&
        children.children.some(checkLink)
      );
    };
    return childrens.some(checkLink);
  };

  const [open, setOpen] = useState(openCollapse(route.children));

  return (
    <Nav.Item as="li">
      <Nav.Link
        onClick={() => {
          setOpen(open);
        }}
        className={classNames('dropdown-indicator cursor-pointer', {
          'text-500': !route.active
        })}
        aria-expanded={open}
        // {...route}
      >
        <NavbarVerticalMenuItem route={route} />
      </Nav.Link>
      <Collapse in={true}>
        <Nav className="flex-column nav" as="ul">
          <NavbarVerticalMenu routes={route.children} />
        </Nav>
      </Collapse>
    </Nav.Item>
  );
};

CollapseItems.propTypes = {
  route: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    children: PropTypes.array.isRequired,
    active: PropTypes.bool
  }).isRequired
};

const NavbarVerticalMenu = ({ routes, label }) => {
  const {
    config: { showBurgerMenu, lang },
    setConfig,
    userInfos
  } = useContext(AppContext);

  const handleNavItemClick = () => {
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu);
    }
  };

  return routes.map((route, index) => {
    if (
      route.type === label &&
      (!route.forWannabeTrader ||
        (route.forWannabeTrader &&
          userInfos &&
          userInfos.trader &&
          !isTraderCompleted(userInfos))) &&
      (!route.isVisibleOnlyForPro ||
        (route.isVisibleOnlyForPro &&
          userInfos &&
          userInfos.pro_tier >= firstProTier)) &&
      (!route.isVisibleOnlyForBasic ||
        (route.isVisibleOnlyForBasic &&
          userInfos &&
          userInfos.pro_tier < firstProTier)) &&
      (!route.minLevel ||
        (route.minLevel && route.minLevel <= userInfos.level)) &&
      (!route.showWithPermission ||
        (route.showWithPermission &&
          canShowRoute(route.showWithPermission, userInfos)))
    ) {
      if (!route.children) {
        return (
          <Nav.Item
            as="li"
            key={index}
            onClick={handleNavItemClick}
            className={
              (route.disableInDesktop ? 'd-block d-sm-none' : '') +
              (route.disableInMobile ? ' d-none d-sm-block' : '')
            }
          >
            <NavLink
              exact={true}
              to={
                route.to === '/authentication-modal'
                  ? { pathname: '/authentication-modal', state: { open: true } }
                  : route.target === '_blank'
                  ? route.testABportfolioLink &&
                    isInTestAB(userInfos, portfolioTest)
                    ? { pathname: route.testABportfolioLink }
                    : { pathname: route.to }
                  : route.to
              }
              target={route.target || ''}
              isActive={match => {
                if (!match) {
                  return false;
                }
                return route.to !== '#!';
              }}
              className={classNames('nav-link', { 'text-500': !route.active })}
              id={route.id || ''}
            >
              <NavbarVerticalMenuItem route={route} userInfos={userInfos} />
            </NavLink>
          </Nav.Item>
        );
      }
      return (
        <CollapseItems
          route={route}
          key={
            typeof route.name === 'object' && route.name !== null
              ? route.name[lang]
              : route.name
          }
        />
      );
    }
  });
};

NavbarVerticalMenu.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape(NavbarVerticalMenuItem.propTypes))
    .isRequired,
  userInfos: PropTypes.object
};

export default NavbarVerticalMenu;
