import { languages } from 'staticData/languages';

export const getLangFromBrowser = () => {
  var userLang = navigator.language || navigator.userLanguage;
  languages.indexOf(userLang) === -1 ? (userLang = 'en-EN') : null;
  return userLang;
};

export const version = 'v3.6.2';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = '0g';
export const settings = {
  isFluid: false,
  isRTL: false,
  isDark: true,
  isReal: false,
  visualType: 'virtual',
  newsRead: [],
  insightsShow: 0,
  lang: 'it-IT',
  navbarPosition: 'vertical',
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: 'transparent',
  proNotifications: null,
  event: '',
  popUpInfo: { day: 0, showAgain: true },
  alertForBot: {}
};

export default { version, navbarBreakPoint, topNavbarBreakpoint, settings };
